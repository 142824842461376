import {useEffect, useState, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import {Grid, Button, Dialog, DialogContent, DialogContentText, TextField, DialogTitle, Box} from '@mui/material'
import ReplyIcon from '@mui/icons-material/Reply'
import DownloadIcon from '@mui/icons-material/Download'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {FormLabel} from 'react-bootstrap'
import {saveAs} from 'file-saver'
import {styled} from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import PolicyCard from '../Cards/PolicyCard'
import Badge from '@mui/material/Badge'
import {useDropzone} from 'react-dropzone'
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined'
import {Buffer} from 'buffer'

import {Formik, useFormikContext} from 'formik'
import * as Yup from 'yup'

// import {AnyObject} from 'yup'

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />} {...props} />)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

export default function BillingMessages() {
  const [expanded, setExpanded] = useState(false)
  const [open, setOpen] = useState(false)
  const [replyMessage, setReplyMessage] = useState()
  const [processingOpen, setProcessingOpen] = useState(false)
  const returnStatus = useSelector((state) => state.member.ReturnStatus)
  const [downloadStatus, setDownloadStatus] = useState('Not Started')
  const [downloadFileName, setDownloadFileName] = useState('')
  const billingMessageDownload = useSelector((state) => state.member.BillingMessageDownload)

  //
  const dispatch = useDispatch()

  function allowMoreEditing() {
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    // setIsLoading(false)
  }

  function finishEditing() {
    // Close All the Modal
    setProcessingOpen(false)
    setOpen(false)
    // setIsLoading(false)
  }

  // Fetch billing messages
  useEffect(() => {
    dispatch(Member.actions.fetchBillingMessages())
  }, [dispatch])

  // Map Incentives to Redux State
  const billingmessages = useSelector((state) => state.member.BillingMessages)

  function ReplyMessage(message) {
    var response = '\n\n <Enter your response above this message> \n\n' + message.msg
    var newMessage = {...message, msg: response}
    setReplyMessage(newMessage)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function First100({msg}) {
    // Get first 100 characters
    let target = 100
    let returnMsg = ''
    if (msg) {
      if (msg.length > target) {
        returnMsg = msg.substring(0, target) + '...'
      } else {
        returnMsg = msg
      }
      //
      return <>{returnMsg}</>
    } else {
      return null
    }
  }

  function FormFiles() {
    const {setFieldValue, errors, values} = useFormikContext()
    const [myFiles, setMyFiles] = useState([])

    // If form is submitted and fails - re populate the files back into the dropzone
    if (values.attachment && values.attachment.length > 0 && myFiles && myFiles.length === 0) {
      setMyFiles(values.attachment)
    }
    //
    const fieldName = 'attachment'
    //

    const onDrop = useCallback(
      (acceptedFiles) => {
        setMyFiles([...myFiles, ...acceptedFiles])
        setFieldValue(fieldName, [...myFiles, ...acceptedFiles])
        console.log(myFiles)
      },
      [myFiles, setFieldValue, fieldName]
    )

    const {getRootProps, getInputProps} = useDropzone({
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/jpg': [],
        'application/pdf': []
      },
      multiple: true,
      maxFiles: 20,
      maxFileSize: 6291457,
      onDrop
    })

    const removeFile = (file) => () => {
      const newFiles = [...myFiles]
      newFiles.splice(newFiles.indexOf(file), 1)
      setMyFiles(newFiles)
      setFieldValue(fieldName, [...newFiles])
    }

    const files = myFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes <button onClick={removeFile(file)}>Remove File</button>
      </li>
    ))
    return (
      <Grid item xs={12} md={12}>
        <section className='container px-0'>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <FileUploadIcon color={'primary'} className={'mt-3 mb-3'} sx={{fontSize: 40}} />
            <p>Drop file here or Click to Upload from device</p>
            <p>(max size 6 MB)</p>
          </div>
          {files.length > 0 && (
            <aside>
              <h4>Files</h4>
              <ul>{files}</ul>
            </aside>
          )}
        </section>

        {errors[fieldName] ? <p className='error-help'>{errors[fieldName]}</p> : ''}
      </Grid>
    )
  }

  const downloadFile = (message) => {
    // console.log(claim)
    if (downloadStatus === 'Not Started') {
      dispatch(Member.actions.fetchBillingMessageDownload({id: message.id}))
      setDownloadFileName(message.filename)
      setDownloadStatus('Fetching')
    }
  }

  // grab the blob from state and tell the browser to download
  const downloadBillingMessageFile = useCallback(() => {
    //   var exportDocName = `Project Schedule - ${header.name}`;
    if (billingMessageDownload && billingMessageDownload !== '') {
      const file = new Blob([Buffer.from(billingMessageDownload, 'base64')], {type: 'application/pdf'})
      saveAs(file, downloadFileName)
    }
  }, [downloadFileName, billingMessageDownload])

  // useEffect to fire when status is 'Fetching' and we get the result in redux
  useEffect(() => {
    if (downloadStatus === 'Fetching' && billingMessageDownload && billingMessageDownload !== '') {
      downloadBillingMessageFile()
      setDownloadStatus('Not Started')
      dispatch(Member.actions.clearBillingMessageDownload())
    }
  }, [downloadStatus, downloadBillingMessageFile, billingMessageDownload, dispatch])

  function Savesubmit(data) {
    // Open Submitting modal
    setProcessingOpen(true)
    // Create structure to load file
    const formData = new FormData()
    // Add fields
    formData.append('id', data['id'])
    formData.append('msg', data['msg'])
    // Check if there are any files
    if (data['attachment'] && data['attachment'].length) {
      // Add files
      for (const fileUploadItem of data['attachment']) {
        formData.append('files', fileUploadItem)
      }
    }
    // Some debugging
    if (process.env.REACT_APP_DEBUG) {
      console.log(data)
    }
    // Save request/form
    dispatch(Member.actions.putBillingMessage({formData}))
  }

  const validationSchema = Yup.object().shape({
    msg: Yup.string().min(2, 'Too Short').max(5000, 'Too Long').required('Message is required')
  })

  const handleChange = (panel) => (event, newExpanded) => {
    dispatch(Member.actions.billingMessageRead({messageID: panel}))
    setExpanded(newExpanded ? panel : false)
  }

  if (billingmessages && billingmessages.length > 0) {
    return (
      <>
        <PolicyCard title='Private Messages' cardClassname={'bg-white primary-border'} titleClassname='text-black pb-3'>
          <div className={'w-100'}>
            {billingmessages
              ? billingmessages.map((message) => {
                  return (
                    <>
                      <Accordion expanded={expanded === message.id} onChange={handleChange(message.id)} key={message.id}>
                        <AccordionSummary aria-controls={message.id} id={message.id}>
                          <Typography>
                            <Badge badgeContent='New' color='primary' invisible={message.readStatus === 'Unread' ? false : true} style={{paddingRight: 40}}>
                              {message.created}: <First100 msg={message.msg} />
                            </Badge>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <Typography>{message.msg}</Typography>
                            <Typography style={{textAlign: 'right'}}>
                              {message.fileStatus === 'Yes' ? (
                                <Button variant='contained' startIcon={<DownloadIcon />} disableElevation onClick={() => downloadFile(message)}>
                                  {downloadStatus === 'Fetching' ? 'Fetching File' : 'Download File'}
                                </Button>
                              ) : null}
                              <Button variant='contained' startIcon={<ReplyIcon />} disableElevation onClick={() => ReplyMessage(message)} style={{marginLeft: 20}}>
                                Reply
                              </Button>
                            </Typography>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )
                })
              : 'None'}
          </div>
        </PolicyCard>

        <Dialog open={open} onClose={handleClose} aria-labelledby='Send Message' fullWidth={true} maxWidth='sm'>
          {processingOpen ? (
            <>
              <DialogTitle>Submitting Message</DialogTitle>
              <DialogContent style={{paddingTop: 30, paddingBottom: 30}}>
                {/* Check for result of submitting message */}
                {returnStatus && returnStatus.length > 0 ? (
                  <>
                    {returnStatus === 'OK' ? (
                      <Card>
                        <Card.Body>
                          <div className='mb-2 bg-light-primary p-8 rounded self-stretch'>
                            <div className='text-primary'>
                              <strong>Message Sent</strong>
                            </div>
                          </div>
                        </Card.Body>
                        <Card.Body>
                          <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                            <Grid container xs={12} gap={3} justifyContent={'end'}>
                              <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => finishEditing()}>
                                Close
                              </Button>
                            </Grid>
                          </Box>

                          <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                            <Grid container xs={12} justifyContent={'space-between'}>
                              <Grid item className={'pr-2'} xs={6}>
                                <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => finishEditing()}>
                                  Close
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Card.Body>
                      </Card>
                    ) : (
                      <Card>
                        <Card.Body>
                          <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                            <div className='text-danger'>
                              <strong>{returnStatus}</strong>
                            </div>
                          </div>
                        </Card.Body>
                        <Card.Body>
                          <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                            <Grid container xs={12} gap={3} justifyContent={'end'}>
                              <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={() => finishEditing()}>
                                Cancel
                              </Button>
                              <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                                Edit and Resubmit
                              </Button>
                            </Grid>
                          </Box>

                          <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                            <Grid container xs={12} justifyContent={'space-between'}>
                              <Grid item className={'pr-2'} xs={6}>
                                <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={() => handleClose()}>
                                  Return to Home
                                </Button>
                              </Grid>
                              <Grid item className={'pl-2'} xs={6}>
                                <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                                  Edit and Resubmit
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Card.Body>
                      </Card>
                    )}
                  </>
                ) : (
                  <DialogContentText>
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait while we process your submission... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
                    </span>
                  </DialogContentText>
                )}
              </DialogContent>
            </>
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={replyMessage}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                Savesubmit(values)
                console.log('Submitting')
                actions.setSubmitting(false)
              }}
            >
              {(props) => {
                const {values, errors, touched, handleChange, handleSubmit} = props
                const change = (name, e) => {
                  e.persist()
                  handleChange(e)
                }
                return (
                  <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
                    <DialogTitle id='form-dialog-title'>Reply to AFSPA (Sent as an internal email)</DialogTitle>
                    <DialogContent>
                      <Grid container justifyContent='center'>
                        <Row className='mb-5 w-100'>
                          <Col xs={12} md={12}>
                            <Card className={'p-5 mt-3'}>
                              <Card.Body>
                                <Grid container columnSpacing={2} rowSpacing={2}>
                                  {/* <Grid flexDirection={'column'} item xs={12} md={12}>
                                    <FormLabel className='gray-1-text flex' htmlFor={'longName'}>
                                      Mesasage ID
                                    </FormLabel>
                                    <TextField
                                      id='BillingMessageID'
                                      type='text'
                                      variant='outlined'
                                      className={'mt-0 w-100'}
                                      inputProps={{'aria-label': 'Member Name'}}
                                      margin='normal'
                                      name='id'
                                      value={values.id}
                                      autoComplete='new-password'
                                    />
                                  </Grid> */}
                                  <Grid flexDirection={'column'} item xs={12} md={12}>
                                    <FormLabel className='gray-1-text flex' htmlFor={'msg'}>
                                      Message
                                    </FormLabel>
                                    <TextField
                                      id='message'
                                      type='text'
                                      variant='outlined'
                                      margin='normal'
                                      name='msg'
                                      className={'mt-0 w-100'}
                                      multiline
                                      rows={10}
                                      value={values.msg}
                                      helperText={touched.msg ? errors.msg : ''}
                                      error={touched.msg && Boolean(errors.msg)}
                                      autoComplete='new-password'
                                      onChange={change.bind(null, 'msg')}
                                      inputProps={{classes: {input: 'input-profile'}, 'aria-label': 'Message Text'}}
                                    />
                                  </Grid>
                                  <FormFiles />
                                </Grid>
                              </Card.Body>
                              <Card.Body>
                                <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                                  <Grid container gap={3} justifyContent={'end'}>
                                    <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={handleClose}>
                                      Cancel
                                    </Button>
                                    <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}}>
                                      Send
                                    </Button>
                                  </Grid>
                                </Box>

                                <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                                  <Grid container justifyContent={'space-between'}>
                                    <Grid item className={'pr-2'} xs={6}>
                                      <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={handleClose}>
                                        Cancel
                                      </Button>
                                    </Grid>
                                    <Grid item className={'pl-2'} xs={6}>
                                      <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} type='submit'>
                                        Save
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Grid>
                    </DialogContent>
                  </form>
                )
              }}
            </Formik>
          )}
        </Dialog>
      </>
    )
  } else {
    return null
  }
}
