import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import HistoryClaimsTable from '../../components/Tables/HistoryClaimsTable'
import {NavLink} from 'react-router-dom'

export default function ClaimsHistoryDashboard() {
  // Determine AETNA SSO Login
  const aetnasso = useSelector((state: any) => state.member.AETNA)
  const [requestAetnaSSO, setrequestAetnaSSO] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!aetnasso && !requestAetnaSSO) {
      dispatch(Member.actions.fetchAETNA())
      setrequestAetnaSSO(true)
    }
  }, [dispatch, aetnasso, requestAetnaSSO])

  const title = 'Claims history'

  return (
    <DashboardBox hasMobileFullWidth>
      <DashboardSection
        name={title}
        className='table_box_xl_device'
        // button={
        //   <NavLink to='/submit-claim'>
        //     <Button variant='contained' startIcon={<AddIcon />} disableElevation>
        //       New Claim
        //     </Button>
        //   </NavLink>
        // }
      >
        <div className='text-start flex flex-col items-start justify-start gap-2.5 mt-3 mb-3'>
          <p className='dashboard-text'>
            {`FSBP claims are also normally available to view within 20 business days via the `}
            <span style={{textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue'}}>
              <a href={aetnasso && aetnasso.cookieparameters} target='_blank' rel='noopener noreferrer'>
                {'AETNA Single Sign On (SSO).'}
              </a>
            </span>
            {` You can view your explanation of Benefits (EOB), digital ID Card, and more. `}
          </p>
          <p className='dashboard-text'>Your FSBP Claim uploads from Member Portal are displayed below.</p>
        </div>
        <HistoryClaimsTable />
      </DashboardSection>
      <div className='table_box_mobile w-full'>
        <DashboardSection
          name={title}
          // button={
          //   <NavLink to='/submit-claim'>
          //     <div role='button' className='circle_button_icon hover:brightness-90'>
          //       <AddIcon style={{color: 'white', fontSize: '26px'}} />
          //     </div>
          //   </NavLink>
          // }
        >
          <div className='w-full h-4'></div>
        </DashboardSection>
        <HistoryClaimsTable displayForMobile />
      </div>
    </DashboardBox>
  )
}
