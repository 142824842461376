import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import {useNavigate, Link} from 'react-router-dom'
import PolicyCard, {CardInfoDisplay} from '../../../../src/app/components/Cards/PolicyCard'
import RequestsCard from '../../../../src/app/components/Cards/RequestsCard'
import CardBlueLink from '../../../../src/app/components/Cards/CardBlueLink'
import PlanCard from '../../components/Cards/PlanCard'
import DashboardSection from '../../components/Cards/DashboardSection'
import DashboardBox from '../../components/DashboardBox'
import {Button} from '@mui/material'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import BillingMessages from '../../components/Tables/BillingMessages'

export default function Dashboard() {
  const dashboardTitle = 'Health Policy Information'
  const dispatch = useDispatch()
  let navigate = useNavigate()
  //
  const bannerdisplay = useSelector((state) => state.auth.bannerDisplay)
  // const splashdisplay = useSelector((state) => state.auth.splashDisplay)
  const accreditation = useSelector((state) => state.member.Flags.AccreditationDisplay)
  const upcomingMaintenanceDisplay = useSelector((state) => state.member.Flags.UpcomingMaintenanceDisplay)
  const upcomingMaintenanceMessage = useSelector((state) => state.member.Flags.UpcomingMaintenanceMessage)
  const outageDisplay = useSelector((state) => state.member.Flags.OutageDisplay)

  const surveyDisplay = false

  // FSBP Plan Information
  const FSBP = useSelector((state) => state.member.FSBP)
  const Dependents = useSelector((state) => state.member.Dependents)
  // Immediate Benefit Plan Information
  const IBP = useSelector((state) => state.member.IBP)
  const IBPPrimaryBeneficiaries =
    IBP && IBP.Secondary && IBP.Secondary['Primary Beneficiary 1'] && IBP.Secondary['Primary Beneficiary 1'].length > 0
      ? {
          ['Primary Beneficiaries']: '',
          ['First']: IBP.Secondary['Primary Beneficiary 1'],
          ['Second']: IBP.Secondary['Primary Beneficiary 2'],
          ['Third']: IBP.Secondary['Primary Beneficiary 3']
        }
      : ''
  const IBPContingentBeneficiaries =
    IBP && IBP.Secondary && IBP.Secondary['Contingent Beneficiary 1'] && IBP.Secondary['Contingent Beneficiary 1'].length > 0
      ? {
          ['Contingent Beneficiaries']: '',
          ['First']: IBP.Secondary['Contingent Beneficiary 1'],
          ['Second']: IBP.Secondary['Contingent Beneficiary 2']
        }
      : ''
  const IBPOtherBeneficiaries =
    IBP && IBP.Secondary && IBP.Secondary['Other Beneficiary 1'] && IBP.Secondary['Other Beneficiary 1'].length > 0
      ? {
          ['Other Beneficiaries']: '',
          ['First']: IBP.Secondary['Other Beneficiary 1'],
          ['Second']: IBP.Secondary['Other Beneficiary 2']
        }
      : ''
  const IBPSecondary = {...IBPPrimaryBeneficiaries, ...IBPContingentBeneficiaries, ...IBPOtherBeneficiaries}
  // Disability Plan Information
  const Disability = useSelector((state) => state.member.Disability)
  // Dental Plan Information
  const Dental = useSelector((state) => state.member.Dental)
  // GEL Plan Information
  const Gel = useSelector((state) => state.member.Gel)
  // CI Plan Information
  const CI = useSelector((state) => state.member.CI)
  // ADND Plan Information
  const ADND = useSelector((state) => state.member.Adnd)
  // New Life Plan Information
  const NewLife = useSelector((state) => state.member.NewLife)
  // Long Term Care Plan Information
  const Ltc = useSelector((state) => state.member.Ltc)
  //

  useEffect(() => {
    dispatch(Member.actions.fetchGel())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchNewLife())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchLtc())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchFSBP())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchDependents())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchIBP())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchDisability())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchDental())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchCI())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchAdnd())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchFlags({bypassToken: null}))
  }, [dispatch])
  return (
    <DashboardBox>
      <DashboardSection name={FSBP ? dashboardTitle : null}>
        <React.Fragment>
          {upcomingMaintenanceDisplay && !outageDisplay ? (
            <div className='mb-5 bg-light-primary p-8 rounded self-stretch'>
              <div className='text-primary font-weight-bold' style={{fontSize: '16px'}}>
                {upcomingMaintenanceMessage}
              </div>
            </div>
          ) : null}

          {bannerdisplay ? (
            <div className='mb-2 bg-light-primary p-8 rounded self-stretch'>
              <div className='mb-5 text-primary'>
                <strong>FSBP One Year Anniversary Survey</strong>
              </div>
              <div className='text-primary'>
                We are so grateful for the opportunity of serving the health care needs of you and your family for the past year. Please take a few minutes and complete the survey below as your
                feedback will help us evaluate our benefits, programs and services.
              </div>
              <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold'>
                <div />
                <Link
                  to={{
                    pathname: '/submit-secureform',
                    state: {
                      requestTypeID: '1049'
                    }
                  }}
                >
                  <Button variant='outlined' color='primary' style={{background: '#daeaf5', marginTop: 5}}>
                    Start Survey
                  </Button>
                </Link>
              </div>
            </div>
          ) : null}
          {accreditation ? (
            <div className='mb-5 bg-light-primary p-8 rounded self-stretch'>
              <div className='text-primary'>
                <strong>Notice of Accreditation Survey for the Foreign Service Benefit Plan</strong>
              </div>
              <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold'>
                <div />
                <a href='https://www.afspa.org/newsitems/aaahc-accreditation-association-for-ambulatory-health-care-notice-of-public-survey/' target='_blank' rel='noreferrer'>
                  <div style={{display: 'inline-block'}}>
                    <Button variant='outlined' color='primary' style={{background: '#daeaf5', marginTop: 5}}>
                      More Information
                    </Button>
                  </div>
                </a>
              </div>
            </div>
          ) : null}
          {surveyDisplay && (!(FSBP && FSBP.h_ter_da) || (FSBP && FSBP.h_ter_da && FSBP.h_ter_da.length === 0)) ? (
            <div>
              <a href='https://forms.office.com/r/mm30TVftrN' target='_blank' rel='noreferrer'>
                <img alt='FSBP Member Survey 2023' src={toAbsoluteUrl('/images/FSBP Member Survey _1_ _004_.png')} />
              </a>
            </div>
          ) : null}

          {FSBP ? (
            <PolicyCard title='Foreign Service Benefit Plan (FSBP) Information'>
              <CardInfoDisplay data={FSBP} />
            </PolicyCard>
          ) : null}

          {Dependents ? (
            <PolicyCard title='Dependents' cardClassname={'bg-white primary-border'} titleClassname='text-black pb-3'>
              <div className='flex flex-col items-start justify-start py-2 px-2 w-full'>
                {Dependents &&
                  Dependents.map((dependent) => {
                    return (
                      <>
                        <CardInfoDisplay title='Dependents' data={dependent} infoTextClassname='gray-text' />
                        <div className='divider mb-2' />
                      </>
                    )
                  })}
              </div>
            </PolicyCard>
          ) : null}

          <BillingMessages />
        </React.Fragment>
      </DashboardSection>

      {FSBP ? (
        <DashboardSection name={'Requests'}>
          <RequestsCard />
        </DashboardSection>
      ) : null}

      {FSBP ? (
        <DashboardSection name={'Other forms'}>
          <div className='flex flex-col items-start self-stretch'>
            <CardBlueLink text={'Benefits/Claims Questions'} onClick={() => navigate('/submit-secureform', {state: {requestTypeID: '1025'}})} />
            <CardBlueLink text={'FSBP Electronic Funds Transfer Form'} onClick={() => navigate('/submit-secureform', {state: {requestTypeID: '1054'}})} />
            <CardBlueLink text={'Enrollment Information Update (ID Card/Marriage/Birth Certificate, etc.)'} onClick={() => navigate('/submit-secureform', {state: {requestTypeID: '1003'}})} />
          </div>
        </DashboardSection>
      ) : null}

      {IBP || IBPSecondary || Disability || Dental || Gel || CI || ADND || NewLife || Ltc ? (
        <DashboardSection name={'My Plans'}>{RenderMyPlans(IBP, IBPSecondary, Disability, Dental, Gel, CI, ADND, NewLife, Ltc)}</DashboardSection>
      ) : null}

      {FSBP ? (
        <DashboardSection name={'Downloads'}>
          <div className='flex flex-col items-start self-stretch'>
            <CardBlueLink
              icon='doc'
              text={'Open Season Material'}
              onClick={() => {
                window.open('https://www.afspa.org/fsbp/new-for-2024/', '_blank', 'noreferrer')
              }}
            />
            <CardBlueLink
              icon='doc'
              text={'2024 FSBP Plan Brochure'}
              onClick={() => {
                window.open('https://www.afspa.org/wp-content/uploads/2024/01/2024_FSBP_Brochure_-RI-72-001_Final_1.8.2024.pdf', '_blank', 'noreferrer')
              }}
            />
            <CardBlueLink
              icon='doc'
              text={'FSBP Benefit Brochure '}
              onClick={() => {
                window.open('https://www.afspa.org/wp-content/uploads/2023/10/AFSPA_FSBP_2024_Summary_Brochure.pdf', '_blank', 'noreferrer')
              }}
            />
            <CardBlueLink
              icon='doc'
              text={'Prescription Benefit Handbook'}
              onClick={() => {
                window.open('https://www.afspa.org/wp-content/uploads/2021/11/2022_FSBP_Rx_Handbook.pdf ', '_blank', 'noreferrer')
              }}
            />
            <CardBlueLink
              icon='doc'
              text={'FSBP Member Rights & Responsibilities'}
              onClick={() => {
                window.open('https://www.afspa.org/wp-content/uploads/2022/08/FSBP-Member-Rights-Responsibilities-08.22.pdf', '_blank', 'noreferrer')
              }}
            />
            <CardBlueLink
              icon='doc'
              text={'FSBP Notice of Privacy Practices'}
              onClick={() => {
                window.open('https://www.afspa.org/wp-content/uploads/2023/09/FSBP-Notice-of-Privacy-Practices-Final_08.23.pdf', '_blank', 'noreferrer')
              }}
            />
          </div>
        </DashboardSection>
      ) : null}
    </DashboardBox>
  )
}

export function RenderMyPlans(IBP, IBPSecondary, Disability, Dental, Gel, CI, ADND, NewLife, Ltc) {
  let navigate = useNavigate()
  return (
    <React.Fragment>
      {IBP ? (
        <PlanCard
          planName='Immediate Benefit Plan'
          balance={IBP ? IBP['Balance Due'] : ''}
          balanceLastUpdated={IBP ? IBP.modified : ''}
          data={[IBP.Primary]}
          buttons={[
            {
              text: 'Immediate Benefit Plan Question',
              onClick: () => {
                navigate('/submit-secureform', {state: {requestTypeID: '1025'}})
              }
            },
            {
              text: 'More Information',
              onClick: () => {
                window.open('https://www.afspa.org/aip/life/', '_blank', 'noreferrer')
              },
              icon: 'doc'
            }
          ]}
          bottomData={IBP ? IBPSecondary : ''}
        />
      ) : null}

      {Disability ? (
        <PlanCard
          planName='Disability Plan'
          balance={Disability ? Disability['Balance Due'] : ''}
          balanceLastUpdated={Disability ? Disability.modified : ''}
          data={[Disability.Primary && Disability.Primary]}
          buttons={[
            {
              text: 'Disability Plan Question',
              onClick: () => {
                navigate('/submit-secureform', {state: {requestTypeID: '1018'}})
              }
            },
            {
              text: 'More Information',
              onClick: () => {
                window.open('https://www.afspa.org/aip/disability/', '_blank', 'noreferrer')
              },
              icon: 'doc'
            }
          ]}
          bottomData={Disability.Secondary && Disability.Secondary}
        />
      ) : null}

      {Dental ? (
        <PlanCard
          planName='Dental Plan'
          balance={Dental ? Dental['Balance Due'] : ''}
          balanceLastUpdated={Dental ? Dental.modified : ''}
          data={[Dental.Primary && Dental.Primary]}
          buttons={[
            {
              text: 'Dental Plan Question',
              onClick: () => {
                navigate('/submit-secureform', {state: {requestTypeID: '2'}})
              }
            },
            {
              text: 'More Information',
              onClick: () => {
                window.open('https://www.afspa.org/aip/dental/', '_blank', 'noreferrer')
              },
              icon: 'doc'
            },
            {
              text: 'Pay via Credit or Debit Card',
              onClick: () => {
                navigate('/makepayment')
              },
              icon: 'credit-card'
            }
          ]}
          bottomData={Dental.Secondary && Dental.Secondary}
        />
      ) : null}

      {Gel ? (
        <PlanCard
          planName='GEL Plan'
          balance={Gel ? Gel['Balance Due'] : ''}
          balanceLastUpdated={Gel ? Gel.modified : ''}
          data={[Gel.Primary && Gel.Primary]}
          buttons={[
            {
              text: 'Gel Plan Question',
              onClick: () => {
                navigate('/submit-secureform', {state: {requestTypeID: '1015'}})
              }
            },
            {
              text: 'More Information',
              onClick: () => {
                window.open('https://www.afspa.org/aip/life/', '_blank', 'noreferrer')
              },
              icon: 'doc'
            },
            {
              text: 'Pay via Credit or Debit Card',
              onClick: () => {
                navigate('/makepayment')
              },
              icon: 'credit-card'
            }
          ]}
          bottomData={Gel.Secondary && Gel.Secondary}
        />
      ) : null}

      {Ltc ? (
        <PlanCard
          planName='Long Term Care'
          balance={Ltc ? Ltc['Balance Due'] : ''}
          balanceLastUpdated={Ltc ? Ltc.modified : ''}
          data={[Ltc.Primary && Ltc.Primary]}
          buttons={[
            {
              text: 'Long Term Care Plan Question',
              onClick: () => {
                navigate('/submit-secureform', {state: {requestTypeID: '1015'}})
              }
            },
            {
              text: 'More Information',
              onClick: () => {
                window.open('https://www.afspa.org/aip/life/', '_blank', 'noreferrer')
              },
              icon: 'doc'
            },
            {
              text: 'Pay via Credit or Debit Card',
              onClick: () => {
                navigate('/makepayment')
              },
              icon: 'credit-card'
            }
          ]}
          bottomData={Ltc.Secondary && Ltc.Secondary}
        />
      ) : null}

      {NewLife ? (
        <PlanCard
          planName='Group Term Life'
          balance={NewLife ? NewLife['Balance Due'] : ''}
          balanceLastUpdated={NewLife ? NewLife.modified : ''}
          data={[NewLife.Primary && NewLife.Primary]}
          buttons={[
            {
              text: 'Gel Plan Question',
              onClick: () => {
                navigate('/submit-secureform', {state: {requestTypeID: '1015'}})
              }
            },
            {
              text: 'More Information',
              onClick: () => {
                window.open('https://www.afspa.org/aip/life/', '_blank', 'noreferrer')
              },
              icon: 'doc'
            },
            {
              text: 'Pay via Credit or Debit Card',
              onClick: () => {
                navigate('/makepayment')
              },
              icon: 'credit-card'
            }
          ]}
          bottomData={NewLife.Secondary && NewLife.Secondary}
        />
      ) : null}

      {ADND ? (
        <PlanCard
          planName='Accidental Death and Dismemberment Plan'
          balance={ADND ? ADND['Balance Due'] : ''}
          balanceLastUpdated={ADND ? ADND.modified : ''}
          data={[ADND.Primary && ADND.Primary]}
          buttons={[
            {
              text: 'Accidental Death and Dismemberment Plan Question',
              onClick: () => {
                navigate('/submit-secureform', {state: {requestTypeID: '1015'}})
              }
            },
            {
              text: 'More Information',
              onClick: () => {
                window.open('https://www.afspa.org/aip/life/', '_blank', 'noreferrer')
              },
              icon: 'doc'
            },
            {
              text: 'Pay via Credit or Debit Card',
              onClick: () => {
                navigate('/makepayment')
              },
              icon: 'credit-card'
            }
          ]}
          bottomData={ADND.Secondary && ADND.Secondary}
        />
      ) : null}

      {CI ? (
        <PlanCard
          planName='Critical Illness Plan'
          balance={CI ? CI['Balance Due'] : ''}
          balanceLastUpdated={CI ? CI.modified : ''}
          data={[CI.Primary && CI.Primary]}
          buttons={[
            {
              text: 'Critical Illness Plan Question',
              onClick: () => {
                navigate('/submit-secureform', {state: {requestTypeID: '1015'}})
              }
            },
            {
              text: 'More Information',
              onClick: () => {
                window.open('https://www.afspa.org/aip/critical-illness/', '_blank', 'noreferrer')
              },
              icon: 'doc'
            },
            {
              text: 'Pay via Credit or Debit Card',
              onClick: () => {
                navigate('/makepayment')
              },
              icon: 'credit-card'
            }
          ]}
          bottomData={CI.Secondary && CI.Secondary}
        />
      ) : null}
    </React.Fragment>
  )
}
